<template>
  <div>
      <section class="notification">
        <div class="container">
          <div class="basket-order col-lg-12 mt-4 mt-lg-0" v-if="pageCount == 0 && loading == false">
            <div class="table-responsive basket-list">
              <div class="empty-box text-center">
                <div class="contents">
                  <div class="empty-image">
                    <img src="../../assets/images/profile-icons/bell.svg" alt="empty-box">
                  </div>
                <h3>No Notification found</h3>
                </div>
              </div>
            </div>
          </div>
          <ul class="notifications-listing list-unstyled mt-4" v-if="pageCount!=0 && loading == false">
            <div
              class="alert alert-success"
              role="alert"
              v-show="enablenotfctnsucmessage"
            >{{ successnotfctnmessage}}</div>
            <li
              v-for="notification in paginatedData"
              v-bind:key="notification.id"
              :class="(notification.is_read=='1') ? 'bg-light text-dark':''"
            >
              <div class="description">{{notification.title}}</div>
              <div class="description">{{notification.description}}</div>
              <div class="date-time">{{notification.date}}</div>
            </li>
          </ul>
          <p class="text-center" v-if="pageCount!=0">
            <button :disabled="pageNumber === 0" @click="prevPage" :style="colorObj" class="btn button-style hoverEffect">Previous</button>
            &nbsp;
            <button
              :disabled="pageNumber >= pageCount - 1"
              @click="nextPage"
              :style="colorObj"
              class="btn button-style hoverEffect"
            >Next</button>
          </p>
        </div>
      </section>
      <div class="container" v-if="loading==true">
        <p class="text-center">
          <img src="../../assets/images/loading.gif" />
        </p>
      </div>
  </div>
</template>
<script>
import { bus } from "../../main";
import helper from "../../store/helper";
export default {
  name: "Notification",
  mixins:[helper],
  data() {
    return {
      profile: {
        user_id: ""
      },
      usernotifctn: {
        user_id: ""
      },
      userreadnotfctn: {
        user_id: "",
        notification_id: "",
        is_read: ""
      },
      userprofile: null,
      user_address: "",
      loctntype: "",
      cityname: "",
      enablesucmessage: false,
      successmessage: "",
      loading:true,
      successadrsmessage: "",
      enablesucsaveadrsmsg: false,
      successsaveadrsmsg: "",
      enableerrmessage: false,
      errormessage: "",

      customerprofile: {
        user_id: "",
        name: "",
        phone: "",
        email: ""
      },
      image: "",
      profiledata: [],
      prflename: "",
      prfldata: [],
      prflephoneno: "",
      getnotification: [],
      prfleemail: "",
      prflepasswrd: null,
      prfleconfrmpass: null,
      prfleimage: "",
      user_picture: "",
      cities: [],
      size: 5,

      getarea: "",
      enablenotfctnsucmessage: false,
      successnotfctnmessage: "",
      pageNumber: 0,
      pageOfItems: [],
      passwordFieldType: "password",
      getprfleimage: "",
      confirmpasswordFieldType: "password"
    };
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
      this.user_address = this.getFilteredUserAddress(this.userprofile);
    } else {
      this.userprofile = null;
    }
    this.getProfile();
    this.getNotification();
    this.getUserAddress();
    bus.$on("setDefaultAddress", () => {
      this.getUserAddress();
    });
    this.originalColor();
  },
  computed: {
    pageCount() {
      if (this.getnotification.status == "1") {
        let l = this.getnotification.data.length,
          s = this.size;
        return Math.ceil(l / s);
      } else {
        return 0;
      }
    },
    paginatedData() {
      if (this.getnotification.status == "1") {
        const start = this.pageNumber * this.size,
          end = start + this.size;
        return this.getnotification.data.slice(start, end);
      } else {
        return 0;
      }
    }
  },
  methods: {
    getUserAddress() {
      this.loading = true;
      this.profile.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.$store.dispatch("customeraddress", this.profile).then(response => {
        var result = JSON.parse(JSON.stringify(response));
        if(result.status == 1){
          var default_address = this.getDefaultAddress(result.data);
          this.user_address = this.getFilteredUserAddress(default_address[0]);
        }
        this.loading = false;
      });
    },
    getDefaultAddress(user_address){
      return user_address.filter(
        function(elem) {
          if (elem.is_default == "1") return elem;
        }
      );
    },
    getFilteredUserAddress(user){
      var tmp_user_address = [];
      tmp_user_address.push(user.address, user.address2, user.area_name, user.city_name, user.zipcode);
      var filtered_user_address = tmp_user_address.filter(function (el) { return el; });
      
      if(filtered_user_address.length > 0){
        return filtered_user_address.join(', ');
      } else {
        return "";
      }
    },
    readNotifictn(notictnid, isreadsts) {
      this.loading = true;
      if (isreadsts == "1") {
        this.userreadnotfctn.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
        this.userreadnotfctn.notification_id = notictnid;
        this.userreadnotfctn.is_read = "0";
        this.$store
          .dispatch("getcustreadnotifictn", this.userreadnotfctn)
          .then(response => {
            this.enablenotfctnsucmessage = true;
            this.successnotfctnmessage = JSON.parse(
              JSON.stringify(response)
            ).message;
            setTimeout(() => (this.enablenotfctnsucmessage = false), 3000);
            this.getNotification();
            this.loading = false;
          });
      }
    },
    nextPage() {
      this.pageNumber++;
    },
    prevPage() {
      this.pageNumber--;
    },
    getNotification() {
      this.loading = true;
      this.usernotifctn.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.$store
        .dispatch("getcustomernotification", this.usernotifctn)
        .then(response => {
          this.getnotification = JSON.parse(JSON.stringify(response));
          this.loading = false;
        });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    confirmswitchVisibility() {
      this.confirmpasswordFieldType =
        this.confirmpasswordFieldType === "password" ? "text" : "password";
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (
        files[0].type == "image/jpeg" ||
        files[0].type == "image/jpg" ||
        files[0].type == "image/png"
      ) {
        window.$(".fileuplderr").hide();
        this.createImage(files[0]);
        this.getprfleimage = files[0];
      } else {
        window.$(".fileuplderr").show();
      }
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.getprfleimage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    getProfile() {
      this.loading = true;
      this.profile.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.$store.dispatch("profiledetails", this.profile).then(response => {
        if(response.status == 1){
        this.profiledata = JSON.parse(JSON.stringify(response.data));
        this.prflename = this.profiledata.name;
        this.prflephoneno = this.profiledata.phone;
        this.prfleemail = this.profiledata.email;
        this.dbprfleimage = this.profiledata.user_picture;
        this.getprfleimage = this.profiledata.user_picture;
        this.prflepasswrd = null;
        }
        this.loading = false;
      });
    },
    validateProfile() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.customerprofile.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
          this.customerprofile.name = this.prflename;
          this.customerprofile.phone = this.prflephoneno;
          this.customerprofile.user_picture = this.$refs.file.files[0];
          this.customerprofile.email = this.prfleemail;
          this.$store
            .dispatch("customermanageprofile", this.customerprofile)
            .then(response => {
              this.prfldata.push({
                profile: JSON.parse(JSON.stringify(response.data))
              });

              if (JSON.parse(JSON.stringify(response)).status == "1") {
                this.enablesucmessage = true;
                this.successmessage = JSON.parse(
                  JSON.stringify(response)
                ).message;
                setTimeout(() => (this.enablesucmessage = false), 3000);
                bus.$emit(
                  "newprofileupdate",
                  JSON.parse(JSON.stringify(this.prfldata[0]))
                );
                window.$("#editprofile").modal("hide");
                this.$toast.success("Profile updated successfully");
              } else {
                this.enableerrmessage = true;
                this.errormessage = JSON.parse(
                  JSON.stringify(response)
                ).message;
                setTimeout(() => (this.enableerrmessage = false), 3000);
              }
              window.$("#editprofile").modal("hide");
              this.$toast.success("Profile updated successfully");
            });
        }
      });
    }
  }
};
</script>
<style>
.hide_file {
  background-color: #24c7fa;
  background-image: url("../../assets/images/svgs/photo-change.svg");
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.fileuplderr {
  display: none;
  margin: 1px 40px -1px -40px;
  width: 100%;
}
</style>
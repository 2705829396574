export default {
    data(){
        return {
          colorObj:{
            backgroundColor: '',
            color:'',
            borderTopColor:'',
            borderRightColor:'',
            borderBottomColor:'',
            borderLeftColor:'',
          },
          store_tax:'',
          pincode_label:'',
          buttonColor:{
            color:'',
            backgroundColor: '',
          },
          textcolor:{
            color:'',
          },
          footerFontColor:{
            color:''
          },
          cancelReOrderButton:{
            backgroundColor:'',
            color:''
          },
          addButtonColor:{
            color:''
          },
          promocodeColor:{
            backgroundColor:'',
            borderTopColor:'',
            borderRightColor:'',
            borderBottomColor:'',
            borderLeftColor:'',
            color:''
          },
          comoboColor:{
            backgroundColor:'',
            color:''
          },
          comobobColor:{
            borderTopColor:'',
            borderRightColor:'',
            borderBottomColor:'',
            borderLeftColor:'',
          },
        }
    },
    created(){
      this.store_tax = localStorage.getItem("store_tax");
      this.pincode_label = localStorage.getItem("pincode_label");
      window.$('<style>.profile-section .tabs .nav-link.active { color: '+localStorage.getItem("css_button_customer_default_font")+'; }</style>').appendTo('body');
      window.$('<style>.profile-section .tabs .nav-link.active { background-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body');
    },
    updated(){
      this.buttonColor.backgroundColor = localStorage.getItem("button_default_color")
      this.cancelReOrderButton.backgroundColor = localStorage.getItem("button_hover_color")
      this.cancelReOrderButton.color = localStorage.getItem("css_button_customer_hover_font")
      this.textcolor.color = localStorage.getItem("button_default_color")
      this.footerFontColor.color = localStorage.getItem("css_footer_font")
      this.colorObj.color = localStorage.getItem("css_button_customer_default_font")
      this.colorObj.borderTopColor = localStorage.getItem("button_default_color")
      this.colorObj.borderRightColor = localStorage.getItem("button_default_color")
      this.colorObj.borderBottomColor = localStorage.getItem("button_default_color")
      this.colorObj.borderLeftColor = localStorage.getItem("button_default_color")
      this.buttonColor.color = localStorage.getItem("css_button_customer_default_font")
      this.promocodeColor.backgroundColor = localStorage.getItem("button_hover_color")
      this.promocodeColor.color = localStorage.getItem("css_button_customer_hover_font")
      this.promocodeColor.borderTopColor = localStorage.getItem("button_default_color")
      this.promocodeColor.borderRightColor = localStorage.getItem("button_default_color")
      this.promocodeColor.borderBottomColor = localStorage.getItem("button_default_color")
      this.promocodeColor.borderLeftColor = localStorage.getItem("button_default_color")
      this.comoboColor.backgroundColor = localStorage.getItem("button_default_color")
      this.comoboColor.color = localStorage.getItem("css_button_customer_default_font")
      this.comobobColor.borderTopColor = localStorage.getItem("button_default_color")
      this.comobobColor.borderRightColor = localStorage.getItem("button_default_color")
      this.comobobColor.borderBottomColor = localStorage.getItem("button_default_color")
      this.comobobColor.borderLeftColor = localStorage.getItem("button_default_color")
      window.$('.hoverEffect').hover(function(){window.$(this).css('background-color',localStorage.getItem("button_hover_color"))})
      window.$('.hoverEffect').hover(function(){window.$(this).css('color',localStorage.getItem("css_button_customer_hover_font"))})
      window.$('.hoverEffect').mouseout(function(){window.$(this).css('background-color',localStorage.getItem("button_default_color"))})
      window.$('.hoverEffect').mouseout(function(){window.$(this).css('color',localStorage.getItem("css_button_customer_default_font"))})
      window.$('.add-button').hover(function(){window.$(this).css('color',localStorage.getItem("button_hover_color"))})
      window.$('.add-button').mouseout(function(){window.$(this).css('color',localStorage.getItem("button_default_color"))})
      window.$('.apply-offer').css('color', localStorage.getItem("button_default_color"))
    },
    methods: {
      originalColor(){
        this.colorObj.backgroundColor = localStorage.getItem("button_default_color")        
      },
      hoverColor(){
        this.colorObj.backgroundColor = localStorage.getItem("button_hover_color")
      },
      buttonOriginalColor(){
        this.buttonColor.backgroundColor = localStorage.getItem("button_default_color")
      },
      buttonHover(){
        this.buttonColor.backgroundColor = localStorage.getItem("button_hover_color")
      }
    }
  }